import React, { useState, useEffect } from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import Errors from "./Pages/error";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Referation from "./Pages/Referation";
import Gizlilik from "./Pages/Gizlilik";
import MainYazilim from "./Pages/hizmetlerimiz/yazilim/MainYazilim";
import MainYonetim from "./Pages/hizmetlerimiz/Yonetim/MainYonetim";
import MainReklam from "./Pages/hizmetlerimiz/Reklam/MainReklam";
import MainTasarim from "./Pages/hizmetlerimiz/Tasarim/MainTasarim";
import Googleads from "./Pages/hizmetlerimiz/Reklam/reklam-down-page/Googleads";
import Sosyalmedya from "./Pages/hizmetlerimiz/Reklam/reklam-down-page/Sosyalmedya";
import Seo from "./Pages/hizmetlerimiz/Reklam/reklam-down-page/Seo";
import WebDesign from "./Pages/hizmetlerimiz/Tasarim/tasarim-down-page/WebDesign";
import Grafic from "./Pages/hizmetlerimiz/Tasarim/tasarim-down-page/Grafic";
import Landing from "./Pages/hizmetlerimiz/Tasarim/tasarim-down-page/Landing";
import WebSoftware from "./Pages/hizmetlerimiz/yazilim/yazilim-down-page/WebSoftware";
import ECommerce from "./Pages/hizmetlerimiz/yazilim/yazilim-down-page/E-commerce";
import Crm from "./Pages/hizmetlerimiz/yazilim/yazilim-down-page/CRM";
import WebYonetim from "./Pages/hizmetlerimiz/Yonetim/yonetim-down-page/WebYonetim";
import Sosyalyonetim from "./Pages/hizmetlerimiz/Yonetim/yonetim-down-page/Sosyalyonetim";
import Googleyonetim from "./Pages/hizmetlerimiz/Yonetim/yonetim-down-page/Googleyonetim";
import Teklifalpage from "./Pages/teklifalpage";
import Preloader from "./Components/loader/Preloader";


function App() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000); // 3.75 seconds to match the preloader animation duration

        return () => clearTimeout(timer);
    }, []);

    if (isLoading) {
        return <Preloader />;
    }

    return (
        <div className="App" style={{ height: "150px" }}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="hakkimizda" element={<About />} />
                    <Route path="*" element={<Errors />} />
                    <Route path="iletisim" element={<Contact />} />
                    <Route path="Referanslarimiz" element={<Referation />} />
                    <Route path="Gizlilik" element={<Gizlilik />} />
                    <Route path="yazilim" element={<MainYazilim />} />
                    <Route path="Yonetim" element={<MainYonetim />} />
                    <Route path="Reklam" element={<MainReklam />} />
                    <Route path="Tasarim" element={<MainTasarim />} />
                    <Route path="reklam/googleAds" element={<Googleads />} />
                    <Route path="reklam/Sosyalmedyareklam" element={<Sosyalmedya />} />
                    <Route path="reklam/Seohizmet" element={<Seo />} />
                    <Route path="Seohizmet" element={<Seo />} />
                    <Route path="Tasarim/Webdesign" element={<WebDesign />} />
                    <Route path="Webdesign" element={<WebDesign />} />
                    <Route path="Tasarim/graficdesign" element={<Grafic />} />
                    <Route path="Tasarim/landingdesign" element={<Landing />} />
                    <Route path="yazilim/WebSoftware" element={<WebSoftware />} />
                    <Route path="yazilim/E-commerce" element={<ECommerce />} />
                    <Route path="E-commerce" element={<ECommerce />} />
                    <Route path="yazilim/CrmErpSoftware" element={<Crm />} />
                    <Route path="Yonetim/Sosyalmedyayonetim" element={<Sosyalyonetim />} />
                    <Route path="Yonetim/Googlereklamyonetim" element={<Googleyonetim />} />
                    <Route path="Yonetim/WebSiteyonetim" element={<WebYonetim />} />
                    <Route path="teklif-al" element={<Teklifalpage />} />
                </Routes>
            </BrowserRouter>


        </div>
    );
}

export default App;
