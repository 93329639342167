import React from 'react';
import Secondbanner from "../Components/Header/SecondBanner/secondbanner";
import TeklifalSection1 from "../Components/teklif-al/TeklifalSection/TeklifalSection1";
import Footer from "../Components/Footer/footer";

const Teklifalpage = () => {
    return (
        <div>
            <Secondbanner/>
            <TeklifalSection1/>
            <Footer/>


        </div>
    );
};

export default Teklifalpage;