import React from 'react';

const GizlilikSection = () => {
    return (
        <section className="pt-0 position-relative overflow-hidden">
            <div className="container">
                <div className="row">
                    <div className="col-12" style={{textAlign:"left"}}>
                        <h2 className="text-primary text-center">Verivizyon</h2>

                        <div>
                            <span className="stabilisation"></span><p style={{textalign:"center"}}><strong>WEB SİTESİ
                            GİZLİLİK POLİTİKASI</strong></p>
                            <p style={{textalign:"left"}}><br/><span className="span_id_sirketin_kanuni_adi">Verivizyon Bilişim Teknolojileri</span> ("Şirket"),
                                internet sitesini ziyaret eden kişilerin paylaşmış olduğu bilgilerin gizliliğini
                                sağlamayı ilke olarak kabul etmiştir. Bu nedenle işbu "Gizlilik Politikası" kişilerin
                                hangi bilgilerinin, hangi yollarla işlendiğini, bu bilgilerin hukuka uygun olarak ve
                                vermiş olduğunuz izin kapsamında hangi üçüncü kişiler ile paylaşıldığını ve Şirket
                                tarafından ne şekilde korunduğunu açıklayarak sizi bilgilendirmek amacı ile
                                oluşturulmuştur. Ayrıca işbu Gizlilik Politikası çerçevesinde bu bilgilerin doğruluğunun
                                sizler tarafından nasıl denetlenebileceği ve istendiğinde Şirketin internet sitesine
                                başvurarak bu bilgilerin silinmesini nasıl sağlayabileceğiniz belirtilmiştir. Şirket
                                Tarafından kişilere her türlü kanaldan sunulan tüm hizmetler kısaca "Hizmet" olarak
                                anılacaktır.</p>
                            <p style={{textalign: "left"}}><strong><br/>Veri Sorumlusu</strong></p>
                            <p>Kişisel veri kimliği belirli veya belirlenebilir gerçek kişiye ilişkin isim, soyisim,
                                doğum tarihi veya telefon numarası gibi her türlü bilgiyi ifade etmektedir.</p>
                            <p>Kişisel verileriniz, veri sorumlusu sıfatıyla <span
                                id="span_id_hangi_ticaret_sicil_mudurlugune_kayitli">Samsun</span> Ticaret Sicil
                                Müdürlüğü nezdinde şirket merkezi <span
                                    id="span_id_sirket_hangi_adreste">İlkadım/Samsun</span> adresinde bulunan <span
                                    className="span_id_sirketin_kanuni_adi">Verivizyon Bilişim Teknolojileri</span> (Şirket)
                                tarafından 6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK) başta olmak üzere,
                                ilgili mevzuat düzenlemeleri ve yetkili otorite kararları/duyuruları ile işbu Gizlilik
                                Politikası çerçevesinde işlenmektedir.</p>
                            <p>Şirket, gizliliğinizin ve kişisel verilerinizin korunmasına ilişkin endişelerinize saygı
                                duymaktadır. Bu kapsamda Şirket, kişisel verilerinizi KVKK başta olmak üzere kişisel
                                verilerin korunmasına ilişkin tüm mevzuat hükümlerine uygun şekilde işlemekte,
                                verilerinizin güvenli şekilde barındırılmasını sağlamakta ve olası hukuka aykırı
                                erişimlere karşı gerekli tüm güvenlik tedbirlerini almaktadır.</p>
                            <p>İşbu Gizlilik Politikası metni, Şirket tarafından aşağıda belirtilen kanallar vasıtası
                                ile hangi kişisel veri kategorilerinizin toplandığı ve hangi süreçler ve amaçlarla
                                işlendiği, hangi alıcı gruplarına aktarıldığı, kişisel verilerinizin korunmasına ilişkin
                                haklarınızı ve veri sorumlusu olarak Şirketin aydınlatma yükümlülüğü kapsamında sizleri
                                bilgilendirmesi gereken diğer açıklamaları içermektedir.</p>
                            <p><strong><span style= {{fontsize: 14}}><br/></span>Gizlilik Politikasının Kapsamı ve
                                Toplanan Veriler</strong></p>
                            <p>Belirli bir kişiye ait kullanılacak ve işlenecek bilgiler, yalnızca bu kişinin kendi
                                istemiyle veri girmesi veya bu hususta açıkça muvafakat vermesi halinde mümkündür. Veri
                                girilmesi veya bu konuda açıkça muvafakat verilmesi, kişinin aşağıda belirtilen şartlar
                                ile mutabık olduğunun göstergesidir. İnternet sitesi ziyaret edildiğinde bazı bilgiler
                                internet sitesi sunucularında depolanır. </p>
                            <p>Bu veriler şu şekilde olabilir: </p>
                            <p style={{paddingleft: "30" }}>- İsim</p>

                            <p style={{paddingleft: "30" }}>- Soyisim</p>

                            <p style={{paddingleft: "30" }}>- E-mail</p>

                            <p style={{paddingleft: "30" }}>- Telefon/Fax numarası</p>

                            <p>Bu veriler doğrultusunda site kullanıcısı hakkında birtakım sonuçlar çıkarılır. Ancak bu
                                kişisel veriler sadece anonim olarak kullanılabilir. Bu verilerin dışarıdan bir hizmet
                                sağlayacısına aktarılması durumunda veri güvenliğine ilişkin mevcut yasal düzenlemeler
                                çerçevesinde aktarılması için gerekli işlemler gerçekleştirilir.</p>
                            <p>Kendi isteğiniz ile kişisel bilgilerin verilmesi halinde Şirket, bu bilgileri yasada
                                öngörülen veya kullanıcının kabul beyanında belirtilen sınırlamalar çerçevesinde
                                kullanacağını ve bu çerçevede işleme tabi tutacağını veya aktarılacağını taahhüt
                                eder.</p>
                            <p><br/>Kişisel verilerin ve çerezlerin kullanım amaçları aşağıda listelenmiştir:</p>
                            <p style={{paddingleft: "30" }}>- Kullanıcıların deneyimlerini özelleştirme</p>

                            <p style={{paddingleft: "30" }}>- Web sitelerine erişim</p>

                            <p style={{paddingleft: "30" }}>- Kullanıcılarla iletişimin sağlanması</p>

                            <p style={{paddingleft: "30" }}>- Rekabet</p>

                            <p style={{paddingleft: "30" }}>- Reklam ve pazarlama</p>

                            <p style={{paddingleft: "30" }}>- Analizler ve pazar araştırması yapma</p>

                            <p style={{paddingleft: "30" }}>- İnternet sitesini yönetme ve kayıt tutma</p>

                            <p>Sunulan hizmetin bir parçası olarak Şirket, hizmet alan kişilere ilişkin bilgileri işbu
                                Gizlilik Politikası kapsamında elde edilebilir ve aktarabilir. Bu tür bilgi aktarımları,
                                üçüncü kişiler tarafından belirlenen şartlara ve üçüncü kişiler ile mevcut sözleşmelere
                                ve yürürlükteki mevcut yasal mevzuata uygun olarak yapılır. Bu Gizlilik Politikası,
                                bilgi aktarımı yapılan üçüncü kişilerin gizlilik uygulamalarını yansıtmamaktadır ve
                                onların gizlilik politikalarından veya uygulamalarından Şirket sorumlu değildir. İşbu
                                Gizlilik Politikası Şirketin kendi kontrolü dışındaki uygulamalar tarafından toplanılan
                                bilgiler, üçüncü taraflara ait internet siteleri ve platformlar tarafından toplanılan
                                bilgiler, üçüncü taraflarca Şirket internet sitesindeki bağlantılar üzerinden toplanan
                                bilgiler veya Şirketin sponsor olduğu veya katıldığı üçüncü taraf internet siteleri
                                üzerindeki başlıklar, kampanyalar ve diğer reklam veya tanıtımları kapsamamaktadır.
                                Üçüncü tarafların kendi internet siteleri yoluyla topladığı, sakladığı ve kullandığı
                                kişisel verilere yönelik yapılan işlemlerden Şirket sorumlu değildir.</p>
                            <p><strong><br/>Çocukların Gizliliği</strong></p>
                            <p>İnternet sitemizde 18 yaşının altındaki çocuklardan bilerek veri toplamamaktadır. Eğer 18
                                yaşının altındaysanız lütfen Hizmetleri kullanarak herhangi bir kişisel verinizi
                                paylaşmayınız. Bu noktada çocukların veli veya vasilerinin işbu Gizlilik Politikasının
                                uygulanması konusunda çocuklarını kişisel verilerini paylaşmamaları konusunda eğiterek
                                yardımları beklenmektedir.</p>
                            <p><strong><br/>Çerez Kullanımı</strong></p>
                            <p>Şirket, anılan kişisel verilerden bazılarını teknik bir iletişim dosyasını (Çerez-cookie)
                                kullanarak elde edebilir. Bahsi geçen teknik iletişim dosyaları, ana bellekte saklanmak
                                üzere bir internet sitesinin kullanıcının tarayıcısına gönderdiği küçük metin
                                dosyalarıdır. Teknik iletişim dosyası bir internet sitesi hakkında durum ve tercihleri
                                saklayarak İnternet'in kullanımını kolaylaştırır. Teknik iletişim dosyası, internet
                                sitesini kaç kişinin kullandığını, bir kişinin internet sitesini hangi amaçla, kaç kez
                                ziyaret ettiğini ve ne kadar kaldıklarını belirten istatistiksel bilgileri elde etmeye
                                ve kullanıcılar için özel tasarlanmış kullanıcı sayfalarından dinamik olarak reklam ve
                                içerik üretilmesine yardımcı olur. Teknik iletişim dosyası, ana bellekte veya
                                e-postalardan veri veya başka herhangi bir kişisel veri almak için tasarlanmamıştır.
                                Tarayıcıların pek çoğu başta teknik iletişim dosyasını kabul eder biçimde
                                tasarlanmıştır, fakat kullanıcılar dilerse teknik iletişim dosyasının gelmemesi veya
                                teknik iletişim dosyası gönderildiğinde ikaz verilmesini sağlayacak biçimde ayarları
                                değiştirebilirler. Bu ayar değişikliğinden kaynaklanan herhangi bir kusurlu çalışma
                                tarafımıza atfedilmemelidir.</p>
                            <p>İnternet sitesinde kullanılan çerezler şu şekildedir: </p>
                            <p style={{paddingleft:"30" }}>- IP adresi ve/veya konumu</p>

                            <p><br/><strong>Hizmetlerden Faydalanabilmek İçin Gereken Veriler</strong></p>
                            <p>Şirket, internet sitesi üzerinden yaptığınız hizmet alımlarında güvenliğinizi
                                önemsemektedir. Bu nedenle kredi kartı bilgileriniz sadece satın alım işlemi
                                gerçekleştirilirken kullanılır ve veri tabanında kayıtlı olarak tutulmaz.</p>
                            <p><strong><br/>Diğer İnternet Sitelerine Verilen Bağlantılar</strong></p>
                            <p>Şirket internet sitesi, içeriğinde üçüncü kişilere ait internet sitelerine yönlendiren
                                bağlantılar/linkler içerebilecek olup işbu Gizlilik Politikası bu internet siteleri için
                                geçerli değildir. Şirket bu internet sitelerine ilişkin herhangi bir sorumluluk kabul
                                etmemektedir.</p>
                            <p><br/><strong>Sosyal Medya</strong></p>
                            <p>Şirket internet sitesi üretilen içeriklerin sosyal medya ağlarında paylaşılabilmesi için
                                bazı sosyal medya ağlarına gömülü şekilde bağlantı vermektedir. Bu bağlantılı hizmetler
                                yalnızca onay verilmesi durumunda çalışmakta olup bu sosyal medya bağlantılarının amacı
                                site içeriklerinin görüntülenmesi ve istenildiğinde arkadaşlarınızla ve yakınlarınızla
                                paylaşabilmenizdir. Bu yönlendirilen sosyal medya ağlarıyla ilgili veri paylaşımı
                                onların sitelerinde ilan ettikleri gizlilik politikalarına tabidir. Bu gizlilik
                                politikalarını da incelemeniz önem taşımaktadır.</p>
                            <p><strong><br/>Bilgi Güncelleme ve Değişiklik</strong></p>
                            <p>Şirket, gizlilik veri koruma prensiplerini güncel tutmak ve ilgili mevzuata uygun hale
                                getirmek için işbu Gizlilik Politikası'nın içeriğini dilediği zaman değiştirebilir.
                                Değişen Gizlilik Politikası Şirket internet sitesinde ilan edilir. Gizlilik
                                Politikası'nın güncel haline <span
                                    id="span_id_sirketin_web_sitesinin_adi">www.Verivizyon.com</span> adresinden sürekli
                                olarak ulaşmanız mümkündür. İşbu Gizlilik Politikası'nın değiştirilmesinden sonra
                                Şirketin hizmet veya uygulamalarını kullanmaya devam etmeniz halinde yapılan
                                değişiklikleri kabul ettiğiniz varsayılır. Şirketin değişiklik yaptığı Gizlilik
                                Politikası hükümleri internet sitesinde yayınlandığı tarihte yürürlük kazanır.</p>


                            <p><br/><strong>Verileri Saklama Süresi</strong></p>
                            <p>Üye olurken doğrudan paylaşılan veriler üyelik süresinin devamı boyunca saklanmaktadır.
                                Bültene abone olurken paylaşılan veriler abonelikten ayrılana kadar sistemde
                                saklanmaktadır. IP adresleri sistemde <span id="span_id_ip_adresi_saklama_suresi">2 Hafta</span> boyunca
                                saklanmaktadır.</p>
                            <p><strong> <br/>Kişisel Verilerin Ne Şekilde İşlenebileceği</strong></p>
                            <p>6698 sayılı Kişisel Verilerin Koruması Kanunu (KVKK) uyarınca, <span
                                className="span_id_sirketin_kanuni_adi">Verivizyon Bilişim Teknolojileri</span> ile
                                paylaşılan kişisel veriler, tamamen veya kısmen, otomatik olarak veya herhangi bir veri
                                kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilerek,
                                kaydedilerek, depolanarak, değiştirilerek, yeniden düzenlenerek kısacası veriler
                                üzerinde gerçekleştirilen her türlü işleme konu olarak tarafımızca işlenebilecektir.
                                KVKK kapsamında veriler üzerinde gerçekleştirilen her türlü işlem "kişisel verilerin
                                işlenmesi" olarak kabul edilmektedir.</p>
                            <p><br/><strong>Kişisel Verilerin Aktarılabileceği Üçüncü Kişi Veya Kuruluşlar Hakkında
                                Bilgilendirme</strong></p>
                            <p>Yukarıda belirtilen amaçlarla, <span className="span_id_sirketin_kanuni_adi">Verivizyon Bilişim Teknolojileri</span> ile
                                paylaşılan kişisel verilerin aktarılabileceği kişi ve kuruluşlar; ana hissedarlar,
                                hissedarlar, reklam verenler, doğrudan veya dolaylı yurtiçi/ yurtdışı iştiraklerimiz;
                                başta <span
                                    className="span_id_sirketin_kanuni_adi">Verivizyon Bilişim Teknolojileri</span> altyapısını
                                kullanan üye firmalar ve bunlarla sınırlı olmamak üzere sunulan hizmet ile ilgili kişi
                                ve kuruluşlar olmak üzere, faaliyetlerimizi yürütmek üzere veya Veri İşleyen sıfatı ile
                                hizmet aldığımız, iş birliği yaptığımız, program ortağı kuruluşları, yurtiçi/yurtdışı
                                kuruluşlar ve diğer 3. kişiler ve kuruluşlardır. Mahkemelerden gelen bağlayıcı taleplere
                                yönelik olarak hukuki yükümlülüklerimiz dahilinde verileriniz istisnai olarak resmi
                                kurumlarla paylaşılabilecektir.</p>
                            <p><span style={{fontsize:"14px"}}><strong><span
                                style={{fontsize:"14px"}}><br/></span></strong></span><strong>KVKK Yürürlüğe Girmeden Önce
                                Elde Edilen Kişisel Veriler</strong></p>
                            <p><span
                                className="span_id_sirketin_kanuni_adi">Verivizyon Bilişim Teknolojileri</span> internet
                                sitesinde KVKK'nın yürürlük tarihi olan 07.04.2016 tarihinden önce depolanan kişisel
                                veri bulunmamaktadır.</p>
                            <p><strong><br/>Veri Sahibinin Hakları</strong></p>
                            <p>Veri sahibi verilerine erişim hakkını her zaman kullanabilir. Buna ek olarak ilgili
                                şartların yerine getirilmesi durumunda KVKK madde 11'de düzenlenen haklar ve aşağıda
                                belirtilen haklar kullanılabilir.</p>
                            <ul style={{textAlign:"left"}}>
                                <li>Düzeltme hakkı,</li>
                                <li>Silme hakkı,</li>
                                <li>İşlemeyi kısıtlama hakkı,</li>
                                <li>Yetkili veri koruma denetim kurumu üzerinden bir şikayet oluşturma hakkı,</li>
                                <li>Veri taşınabilirliği hakkı.</li>
                            </ul>
                            <p>Kişisel verilerin, Şirketin kanuni bir faydaya sahip olduğu zeminde işlenmesine ilişkin
                                faaliyetler söz konusu olduğunda, içinde bulunulan özel durum sonucunda oluşan
                                gerekçeler dolayısıyla, veri sahibi kişisel verilerin işlenmesine itiraz hakkına
                                sahiptir. Şirket, verilerin işlenmesine ilişikin olarak koruması gereken çıkarların,
                                hakların ve özgürlüklerin üzerinde yer alan önemli bir gerekçe olduğunu kanıtlayamadığı
                                veya bu işlem kanuni hak taleplerde bulunmak, bu talepleri kullanmak veya savunmak gibi
                                amaçlara hizmet ettiği sürece Şirket verilerin işlenmesini durduracaktır.</p>
                            <p>Kişisel verilerin işlenmesine onay verilmesi halinde onayın geri çekilmesi imkanı
                                vardır.</p>
                            <p>Kanun kapsamında, kişisel verileriniz ile ilgili her türlü talep, şikayet ve
                                önerilerinizi, kimliğinizi tevsik edici belgeler ve talebinizi içeren dilekçeniz ile
                                aşağıdaki adresimize ulaştırabilir veya güvenli elektronik imza kullanmak suretiyle
                                Şirketimizin <span
                                    id="span_id_sikayet_ve_oneriler_icin_e_posta">info@Verivizyon.com</span> kayıtlı
                                elektronik posta adresine gönderebilirsiniz.</p>
                            <p><br/>Adresimiz:</p>
                            <p><span id="span_id_sirket_adresi">İlkadım/Samsun</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GizlilikSection;