import React from 'react';
import './section_2_hmpg.css';

const Hmpgsection2 = () => {

    return (
        <section className="mt-md-5 py-md-5 section-bg">
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-lg-8">
                        <h2 className="fs-sm-24"><span
                            className="font-w-4 d-block">Daha görünür olmak için </span> Dijital dünyanın çalışma
                            prensiplerini bizimle keşfedin</h2>
                        <p className="lead mb-0 fs-18 fs-sm-14">Dijital rekabet sandığınız kadar karmaşık değil. </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hmpgsection2;