import React from 'react';


import Buttonsection from "../Components/button/Buttonsection";
import Teklifalsection2 from "../Components/teklif-al/TeklifalSection/teklifalsection2";







const Error = () => {
    return (

<div>

    <Teklifalsection2/>
</div>


);
};

export default Error;