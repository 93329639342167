import React from 'react';
import { IoIosStarOutline } from "react-icons/io";
const Fikirsection = () => {
    return (
        <section className="pt-0 custom-pb-1">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-lg-5 mb-5 mb-lg-0">
                        <div className="mb-5">
                            <h3 className="fs-34"><span className="font-w-4 d-block">Fikirleriniz her zaman</span> Bizim
                                İçin Önemlidir.</h3>
                            <p className="lead mb-0">Projenizin her sürecinde sizinle birlikteyiz. İsteklerinizi tamamen
                                anlamak ve zaman kaybını önlemek için sizinle sürekli irtibat halindeyiz.</p>
                        </div>
                        <img src="https://ik.imagekit.io/lgf1wyqnvg/verivizyon-hizmetler/yazilim%20(1).svg?updatedAt=1715447626085" className="img-fluid" alt=""/>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="p-4 rounded shadow">
                                    <div className="bg-primary p-2 d-inline-block rounded">
                                        <div className="f-icon-m text-white"><IoIosStarOutline  style={{width:60,fontSize:52}}/>
                                        </div>
                                    </div>
                                    <h3 className="fs-20 mt-4 mb-3">Web Site Yönetim Paneli</h3>
                                    <p className="mb-0">Web site yazılı ve görsel içerik yönetimi de dahil olmak üzere
                                        minimum çaba ile web site yönetiminin sağlandığı panel yazılımlarıdır.</p>
                                </div>
                            </div>
                            <div className="col-md-6 mt-5">
                                <div className="p-4 rounded shadow">
                                    <div className="bg-primary p-2 d-inline-block rounded">
                                        <div className="f-icon-m text-white"><IoIosStarOutline  style={{width:60,fontSize:52}}/>
                                        </div>
                                    </div>
                                    <h3 className="fs-20 mt-4 mb-3">Özel Web Site Yazılımları</h3>
                                    <p className="mb-0">Sizin ihtiyacınız olan yazılım dili ile kodlanan ve sonuç olarak
                                        sizin için ideal yazılımsal donanımların yer aldığı özel web site
                                        yazılımlarıdır.</p>
                                </div>
                            </div>
                            <div className="col-md-6 mt-5 mt-md-0">
                                <div className="p-4 rounded shadow">
                                    <div className="bg-primary p-2 d-inline-block rounded">
                                        <div className="f-icon-m text-white"><IoIosStarOutline  style={{width:60,fontSize:52}}/>
                                        </div>
                                    </div>
                                    <h3 className="fs-20 mt-4 mb-3">SMS Sistemi</h3>
                                    <p className="mb-0">Düşük bütçe ile daha geniş kullanıcı kitlesine erişmenizi
                                        sağlayan toplu SMS gönderiminin sağlandığı yazılımlardır.</p>
                                </div>
                            </div>
                            <div className="col-md-6 mt-5">
                                <div className="p-4 rounded shadow">
                                    <div className="bg-primary p-2 d-inline-block rounded">
                                        <div className="f-icon-m text-white"><IoIosStarOutline  style={{width:60,fontSize:52}}/>
                                        </div>
                                    </div>
                                    <h3 className="fs-20 mt-4 mb-3">Mikro E-Ticaret</h3>
                                    <p className="mb-0">Küçük ve orta ölçekli işletmeler için yüksek güvenlikli
                                        altyapıya sahip bir şekilde düşük bütçelerle daha fazla müşteriye erişme
                                        imkanını sağlayan basit ve sade e-ticaret yazılımlarıdır.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

);
};

export default Fikirsection;