import React from 'react';
// import qrpicture from '../../Picture/qrken.svg';
const Hmpgsection4 = () => {
    return (
        <section className="section-bg">
            <div className="container">
                <div className="d-flex flex-md-row flex-column align-items-center">
                    <div className="width-max-content">
                        <div>
                            <span className="font-w-4 d-block fs-30">Örnek Projemiz</span>
                            <h2 className="mb-0 ">VeriVizyon Muhasebe</h2>
                        </div>
                    </div>
                    <div className=" ps-lg-5 my-3 my-lg-0 px-2">
                        <p className="lead mb-0">İşletmenizin büyümesine destek olacak yapay zeka destekli muhasebe programımız, veri vizyonuyla satışlarınızı artırmak için mükemmel bir çözüm sunuyor</p>
                    </div>
                    <div className="width-max-content text-lg-end mb-3">
                        <a href="#" target="_blank" className="d-block">
                            <img src="https://ik.imagekit.io/lgf1wyqnvg/verivizyon/Verivizyon%20logo%20siyah%20beyaz234.png?updatedAt=1715252456433" alt="" className="img-fluid"
                                 style={{maxHeight:105}}/>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hmpgsection4;